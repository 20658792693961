import React, { useState } from "react";
import { FaSearch, FaEdit , FaClone, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import PracticeScheduleForm from "./PracticeScheduleForm";
import PracticeScheduleReview from "./PracticeScheduleReview";
import "./PracticePlan.css";

function PracticePlan() {
  const [activeTab, setActiveTab] = useState("template");
  const [showForm, setShowForm] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [formData, setFormData] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  

  const handleFormSubmit = (data) => {
    setFormData(data);
    setShowForm(false);
    setShowReview(true);
  };

  const handleCreateTemplate = (template) => {
    setTemplates((prevTemplates) => [...prevTemplates, template]);
    setShowReview(false);
    setShowForm(false);
  };

  const handleCreateClick = () => {
    setFormData({
      templateName: "",
      date: "",
      startTime: "08:00",
      endTime: "10:00",
      selectedPlayers: ["Group A", "Group B"],
      notes: "",
      timeIntervals: [], // Add empty table data initially
    });
    setShowForm(true);
    setShowReview(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleDuplicate = (template) => {
    const duplicatedTemplate = {
      ...template,
      templateName: `${template.templateName} (Copy)`,
    };
    setTemplates((prevTemplates) => [...prevTemplates, duplicatedTemplate]);
  };

  const handleDelete = (index) => {
    if (window.confirm("Are you sure you want to delete this practice plan?")) {
      setTemplates((prevTemplates) =>
        prevTemplates.filter((_, idx) => idx !== index)
      );
    }
  };

  const handleView = (template) => {
    navigate("/practice-details", { state: { template } });
  };

  const filteredTemplates = templates.filter((template) =>
    template.templateName.toLowerCase().includes(searchQuery)
  );

  const formatTime = (time) => {
    if (!time) return "N/A";
    const [hour, minute] = time.split(":");
    const isPM = hour >= 12;
    const formattedHour = isPM ? hour % 12 || 12 : parseInt(hour, 10);
    const period = isPM ? "PM" : "AM";
    return `${formattedHour}:${minute} ${period}`;
  };

  return (
    <div className="practice-plan">
      <div className="tabs">
        <button
          className={`tab ${activeTab === "template" ? "active-tab" : ""}`}
          onClick={() => {
            setActiveTab("template");
            setShowForm(false);
            setShowReview(false);
          }}
        >
          Practice Schedule
        </button>
        <button
          className={`tab ${activeTab === "schedule" ? "active-tab" : ""}`}
          onClick={() => {
            setActiveTab("schedule");
            setShowForm(false);
            setShowReview(false);
          }}
        >
          Upcoming Practices
        </button>
      </div>

      <div className="tab-content">
        {/* Practice Templates Section */}
        {activeTab === "template" && !showForm && !showReview && (
          <div className="template-section">
            <h3>Practice Schedule</h3>
            <div className="actions">
              <button className="create-button" onClick={handleCreateClick}>
                + Create
              </button>
              <div className="search-container">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search practice plans"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            {filteredTemplates.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Practice Name</th>
                    <th>Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Notes</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTemplates.map((template, index) => (
                    <tr key={index}>
                      <td>{template.templateName}</td>
                      <td>{template.date}</td>
                      <td>{formatTime(template.startTime)}</td>
                      <td>{formatTime(template.endTime)}</td>
                      <td title={template.notes}>
                        {template.notes.length > 30
                          ? `${template.notes.slice(0, 30)}...`
                          : template.notes || "No notes"}
                      </td>
                      <td>
                        <button
                          className="action-button"
                          onClick={() => handleView(template)}
                          title="View"
                        >
                          <FaEdit  />
                        </button>&nbsp;
                        <button
                          className="action-button"
                          onClick={() => handleDuplicate(template)}
                          title="Duplicate"
                        >
                          <FaClone />
                        </button>&nbsp;
                        <button
                          className="action-button delete"
                          onClick={() => handleDelete(index)}
                          title="Delete"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No practice plans available.</p>
            )}
          </div>
        )}

        {/* Show PracticeScheduleForm */}
        {showForm && (
          <div className="form-section">
            <PracticeScheduleForm onFormSubmit={handleFormSubmit} />
          </div>
        )}

        {/* Show PracticeScheduleReview */}
        {showReview && formData && (
          <PracticeScheduleReview
            formData={formData}
            onCreateTemplate={handleCreateTemplate}
            onGoBack={() => {
              setShowReview(false);
              setShowForm(true);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default PracticePlan;
