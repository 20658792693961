import React, { useState } from "react";
//import { FaSearch} from "react-icons/fa";

import "./DrillHub.css";

function DrillHub() {
  const [activeTab, setActiveTab] = useState("library"); // Track active tab
  const [drills, setDrills] = useState([]); // Track added drills
  const [searchQuery, setSearchQuery] = useState("");
  const [newDrill, setNewDrill] = useState({
    name: "",
    details: "",
    tag: "",
    link: "",
    notes: "",
    files: [], // Array to hold multiple files
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDrill({ ...newDrill, [name]: value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    const filesWithPreview = files.map((file) => {
      const fileUrl = URL.createObjectURL(file);
      const fileType = file.type.split("/")[0]; // Get the main type (e.g., image, video, etc.)
      return { file, fileUrl, fileType };
    });
    setNewDrill({ ...newDrill, files: [...newDrill.files, ...filesWithPreview] });
  };

  const handleAddDrill = () => {
    try {
      if (!newDrill.name || !newDrill.details) {
        alert("Please fill in the drill name and details");
        return;
      }

      if (newDrill.link && !isValidUrl(newDrill.link)) {
        alert("Please enter a valid URL");
        return;
      }

      // Add the new drill with files array
      setDrills((prevDrills) => [
        ...prevDrills,
        { ...newDrill, files: [...newDrill.files] }, // Include files array
      ]);

      // Reset the form
      setNewDrill({
        name: "",
        details: "",
        tag: "",
        link: "",
        notes: "",
        files: [], // Clear files after submitting
      });
      setActiveTab("library"); // Switch to Drill Library tab after adding
    } catch (error) {
      console.error(error);
      alert("An error occurred while adding the drill");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredDrills = drills.filter((drill) =>
    drill.name.toLowerCase().includes(searchQuery)
  );

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const handleViewFile = (fileUrl) => {
    // Open the file in a new tab
    window.open(fileUrl, "_blank");
  };

  const getFileTypeLabel = (fileType) => {
    switch (fileType) {
      case "image":
        return "Image File: ";
      case "pdf":
        return "PDF Document: ";
      case "video":
        return "Video File: ";
      default:
        return "File";
    }
  };

  return (
    <div className="drillhub">
      <div className="tabs">
        <button
          className={`tab ${activeTab === "library" ? "active-tab" : ""}`}
          onClick={() => setActiveTab("library")}
        >
          Drill Library
        </button>
        <button
          className={`tab ${activeTab === "add" ? "active-tab" : ""}`}
          onClick={() => setActiveTab("add")}
        >
          Add Drill
        </button>
      </div>

      <div className="tab-content">
        {/* Drill Library Section */}
        {activeTab === "library" && (
          <div className="drill-library">
            <h3>Drill Library</h3>
            <div className="search-section">
              <input
                type="text"
                placeholder="Search drills"
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input"
              />
            </div>
            {filteredDrills.length > 0 ? (
              <table className="drill-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Details</th>
                    <th>Tag</th>
                    <th>Link</th>
                    <th>Notes</th>
                    <th>Attachments</th> {/* Added Attachments Column */}
                  </tr>
                </thead>
                <tbody>
                  {filteredDrills.map((drill, index) => (
                    <tr key={index}>
                      <td>{drill.name}</td>
                      <td>{drill.details}</td>
                      <td>{drill.tag}</td>
                      <td>
                        {drill.link ? (
                          <a href={drill.link} target="_blank" rel="noreferrer">
                            View
                          </a>
                        ) : (
                          "No Link"
                        )}
                      </td>
                      <td>{drill.notes}</td>
                      <td>
                        {drill.files.length > 0 ? (
                          drill.files.map((file, fileIndex) => (
                            <div key={fileIndex}>
                              <span>{getFileTypeLabel(file.fileType)}</span>
                              <button onClick={() => handleViewFile(file.fileUrl)}>
                                Open Attachment
                              </button>
                            </div>
                          ))
                        ) : (
                          "No Attachments"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No drills available. Add a new drill!</p>
            )}
          </div>
        )}

        {/* Add Drill Section */}
        {activeTab === "add" && (
          <div className="add-drill-section">
            <h3>Add Drill</h3>
            <div className="form-container">
              <label>Drill Name</label>
              <input
                type="text"
                name="name"
                value={newDrill.name}
                onChange={handleInputChange}
              />

              <label>Drill Details</label>
              <textarea
                name="details"
                value={newDrill.details}
                onChange={handleInputChange}
              ></textarea>

              <label>Drill Tag</label>
              <input
                type="text"
                name="tag"
                value={newDrill.tag}
                onChange={handleInputChange}
              />

              <label>Drill Link</label>
              <input
                type="url"
                name="link"
                value={newDrill.link}
                onChange={handleInputChange}
              />

              <label>Coaches Notes</label>
              <textarea
                name="notes"
                value={newDrill.notes}
                onChange={handleInputChange}
              ></textarea>

              <div className="file-upload">
                <label htmlFor="file-upload" className="file-label">
                  + Select Files
                </label>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                {newDrill.files.length > 0 && (
                  <div>
                    <h4>Uploaded Files:</h4>
                    {newDrill.files.map((file, index) => (
                      <div key={index}>
                        <p>{file.file.name}</p>
                        {file.fileType === "image" && (
                          <img src={file.fileUrl} alt="Uploaded file preview" className="file-preview" />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <button className="add-drill-button" onClick={handleAddDrill}>
                Add Drill
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DrillHub;
