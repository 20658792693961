import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Header"; // Import Header component
import Sidebar from "./Sidebar"; // Import Sidebar component
import PracticePlan from "./PracticePlan"; // Import Practice Plan page
import DrillHub from "./DrillHub"; // Import Drill Hub page
import Rosters from "./Rosters"; // Import Rosters page
import Players from "./Players"; // Import Players page
import PracticeDetails from "./PracticeDetails"; // Import Practice Details page
import "./App.css";

function App() {
  // User data for the sidebar (can be updated dynamically in the future)
  const user = {
    name: "Adam Cross",
    location: "Waterloo Ontario",
    role: "Head Coach",
    profilePicture: "./images/profile.jpg", // Replace with the profile image path
  };

  return (
    <Router>
      <div className="app-container">
        {/* Sidebar */}
        <Sidebar user={user} />

        {/* Main Content */}
        <div className="main-content">
          {/* Header */}
          <Header />

          {/* Page Routes */}
          <div className="page-content">
            <Routes>
              <Route path="/practice-plan" element={<PracticePlan />} />
              <Route path="/drill-hub" element={<DrillHub />} />
              <Route path="/rosters" element={<Rosters />} />
              <Route path="/players" element={<Players />} />
              <Route path="/practice-details" element={<PracticeDetails />} />
              <Route path="/" element={<h1>Welcome to GameDay.AI</h1>} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
