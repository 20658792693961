import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaFootballBall,FaSave } from "react-icons/fa";
import "./PracticeDetails.css";

function PracticeDetails({ onReadyToPractice }) {
  const location = useLocation();
  const navigate = useNavigate();

  const { template } = location.state || {};

  const {
    templateName,
    date,
    startTime,
    endTime,
    selectedPlayers,
    notes,
    timeIntervals = [],
  } = template || {};

  const [currentIntervals] = useState(
    timeIntervals.map((interval) => ({ ...interval }))
  );
  const [practiceName, setPracticeName] = useState(templateName || "");
  const [practiceDate, setPracticeDate] = useState(date || "");
  const [practiceStartTime] = useState(startTime || "");
  const [practiceEndTime] = useState(endTime || "");
  const [cellDrills, setCellDrills] = useState({});
  const [showDrillModal, setShowDrillModal] = useState(false);
  const [currentDrillCell, setCurrentDrillCell] = useState(null);
  const [viewDrillDetails, setViewDrillDetails] = useState(null);
  const [currentDrillIndex, setCurrentDrillIndex] = useState(0);


  const handleReadyToPractice = () => {
    const practiceDetails = {
      practiceName,
      practiceDate,
      startTime: practiceStartTime,
      endTime: practiceEndTime,
      notes,
      selectedPlayers,
      timeIntervals: currentIntervals,
      drills: cellDrills,
    };
  
    console.log("Saving practice details to localStorage:", practiceDetails); // Debug log
    localStorage.setItem("upcomingPractice", JSON.stringify(practiceDetails));
    alert(`Practice on ${practiceDate} added to Upcoming Practices.`);
    navigate("/upcoming-practice");
  };
  
  const openDrillModal = (time, player) => {
    setCurrentDrillCell({ time, player });
    setShowDrillModal(true);
  };

  const handleAddDrill = (drillDetails) => {
    if (currentDrillCell) {
      const { time, player } = currentDrillCell;
      const cellKey = `${time}-${player}`;
      setCellDrills((prev) => ({
        ...prev,
        [cellKey]: [...(prev[cellKey] || []), drillDetails],
      }));
      setShowDrillModal(false);
      setCurrentDrillCell(null);
    }
  };

  const handleViewDrills = (time, player) => {
    const cellKey = `${time}-${player}`;
    const drills = cellDrills[cellKey];
    if (drills) {
      setViewDrillDetails(drills);
      setCurrentDrillIndex(0);
    } else {
      alert("No drill details available for this cell.");
    }
  };

  const mergeVerticalCells = (intervals, player) => {
    const merged = [];
    let previous = null;

    intervals.forEach((interval, idx) => {
      const assignment = interval.assignments[player];
      if (
        previous &&
        assignment &&
        previous.assignment &&
        previous.assignment.activity === assignment.activity &&
        previous.assignment.color === assignment.color
      ) {
        previous.rowSpan += 1;
      } else {
        if (previous) merged.push(previous);
        previous = { index: idx, rowSpan: 1, assignment };
      }
    });

    if (previous) merged.push(previous);

    return merged;
  };

  const verticalMerges = selectedPlayers.reduce((acc, player) => {
    acc[player] = mergeVerticalCells(currentIntervals, player);
    return acc;
  }, {});

  return (
    <div className="practice-details">
      <h1>Practice Plan Details</h1>

      <div className="details-summary">
        <h4><lable>Practice Name:</lable>
          <input
            type="text"
            value={practiceName}
            onChange={(e) => setPracticeName(e.target.value)}
          />
        </h4>
        <p>
          <strong>Date:</strong>
          <input
            type="date"
            value={practiceDate}
            onChange={(e) => setPracticeDate(e.target.value)}
          />
        </p>
        <p>
          <strong>Start Time:</strong> {practiceStartTime}
        </p>
        <p>
          <strong>End Time:</strong> {practiceEndTime}
        </p>
        <p>
          <strong>Notes:</strong> {notes || "No notes provided."}
        </p>

      </div>

      <h3>Practice Schedule</h3>
      {currentIntervals && currentIntervals.length > 0 ? (
        <table className="time-interval-table">
          <thead>
            <tr>
              <th>Time</th>
              {selectedPlayers.map((player, idx) => (
                <th key={idx}>{player}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentIntervals.map((interval, rowIndex) => (
              <tr key={rowIndex}>
                <td>{interval.time}</td>
                {selectedPlayers.map((player, playerIdx) => {
                  const mergeData = verticalMerges[player].find(
                    (merge) => merge.index === rowIndex
                  );

                  if (!mergeData || mergeData.index !== rowIndex) return null;

                  return (
<td
  key={playerIdx}
  rowSpan={mergeData.rowSpan}
  style={{
    backgroundColor: mergeData.assignment?.color || "#fff",
    color:
      mergeData.assignment?.color === "#000000"
        ? "#fff"
        : "#000",
    textAlign: "center",
    position: "relative",
  }}
>
  {mergeData.assignment?.activity}
  {mergeData.assignment && (
    <>
      {cellDrills[`${interval.time}-${player}`] && (
        <FaFootballBall
          className="drill-icon"
          onClick={() =>
            handleViewDrills(interval.time, player)
          }
          style={{ marginLeft: "5px", cursor: "pointer" }}
        />
      )}
      <span style={{ display: 'inline-flex', alignItems: 'center', gap: '5px', position: 'absolute', bottom: '5px', right: '5px' }}>
        <FaPlus
          className="add-drill-icon"
          onClick={() =>
            openDrillModal(interval.time, player)
          }
          style={{
            cursor: "pointer",
          }}
        />
        <span style={{ fontSize: '12px', cursor: 'pointer' }} onClick={() => openDrillModal(interval.time, player)}>
          Add Drill
        </span>
      </span>
    </>
  )}
</td>

                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No schedule data available.</p>
      )}
        <button onClick={() => navigate("/practice-plan")} className="back-button">
          Back to Practice Plans
        </button>
        <button className="ready-button" onClick={handleReadyToPractice}>
          Save&nbsp;<FaSave />
        </button>
        
      {showDrillModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Add Drill</h3>
            <button
              onClick={() => alert("Choose Drill from Library functionality coming soon!")}
            >
              Choose from Library
            </button>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                const drillDetails = Object.fromEntries(formData.entries());
                handleAddDrill(drillDetails);
              }}
            >
              <label>
                Drill Name:
                <input type="text" name="name" required />
              </label>
              <label>
                Drill Details:
                <textarea name="details" required></textarea>
              </label>
              <label>
                Drill Tag:
                <input type="text" name="tag" />
              </label>
              <label>
                Drill Link:
                <input type="url" name="link" />
              </label>
              <label>
                Coaches Notes:
                <textarea name="notes"></textarea>
              </label>
              <label>
                Attach Files:
                <input type="file" name="files" multiple />
              </label>
              <button type="button" onClick={() => setShowDrillModal(false)}>
                Cancel
              </button> &nbsp;
              <button type="submit">Save Drill</button> 

            </form>
          </div>
        </div>
      )}

      {viewDrillDetails && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Drill Details</h3>
            <div>
              {viewDrillDetails.length > 0 && (
                <>
                  <p>
                    <strong>Name:</strong> {viewDrillDetails[currentDrillIndex].name}
                  </p>
                  <p>
                    <strong>Details:</strong> {viewDrillDetails[currentDrillIndex].details}
                  </p>
                  <p>
                    <strong>Tag:</strong> {viewDrillDetails[currentDrillIndex].tag}
                  </p>
                  <p>
                    <strong>Link:</strong>{" "}
                    <a
                      href={viewDrillDetails[currentDrillIndex].link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Link
                    </a>
                  </p>
                  <p>
                    <strong>Notes:</strong> {viewDrillDetails[currentDrillIndex].notes}
                  </p>
                  {Array.isArray(viewDrillDetails[currentDrillIndex].files) && (
                    <div>
                      <strong>Attachments:</strong>
                      <ul>
                        {viewDrillDetails[currentDrillIndex].files.map((file, index) => (
                          <li key={index}>
                            <a href={file} target="_blank" rel="noreferrer">
                              View Attachment {index + 1}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <button onClick={() => setViewDrillDetails(null)}>Close</button> &nbsp;
                  <button
                    onClick={() =>
                      setCurrentDrillIndex(
                        (prev) => (prev + 1) % viewDrillDetails.length
                      )
                    }
                  >
                    Next
                  </button>
                  
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
}

export default PracticeDetails;
