import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { FaBell, FaUser, FaCog } from "react-icons/fa";
import "./Header.css";
//import logo from "./images/logo.png";

function Header() {
  const location = useLocation(); // Get the current URL path

  return (
    <header className="header">
      <div className="nav-left">
        {/* Logo Section */}
        {/*<div className="logo-container">
          {/* <img src={logo} alt="Logo" className="logo" />
        </div>*/}
        {/* Navigation Links */}
        <nav className="nav-bar">
          <Link
            to="/"
            className={`nav-link ${
              location.pathname === "/" ? "active" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/practice-plan"
            className={`nav-link ${
              location.pathname === "/practice-plan" ? "active" : ""
            }`}
          >
            Practice Plan
          </Link>
          <Link
            to="/drill-hub"
            className={`nav-link ${
              location.pathname === "/drill-hub" ? "active" : ""
            }`}
          >
            Drill Hub
          </Link>
          <Link
            to="/rosters"
            className={`nav-link ${
              location.pathname === "/rosters" ? "active" : ""
            }`}
          >
            Rosters
          </Link>
          <Link
            to="/players"
            className={`nav-link ${
              location.pathname === "/players" ? "active" : ""
            }`}
          >
            Players
          </Link>
        </nav>
      </div>
      <div className="nav-right">
        {/* Icons for Notifications, User, and Settings */}
        <FaBell className="nav-icon" title="Notifications" />
        <FaUser className="nav-icon" title="User Profile" />
        <FaCog className="nav-icon" title="Settings" />
      </div>
    </header>
  );
}

export default Header;
